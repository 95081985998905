import React, { ChangeEvent, useCallback } from "react";
import { Input, InputGroupTextProps, Label } from "reactstrap";
import { Wrapper } from "./styles";
import { Props } from "./types";

type InputProps = Props & InputGroupTextProps;

const NumberInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({ label, name, onChange, ...rest }, ref) => {
        const handleChange = useCallback((eventTarget: ChangeEvent<HTMLInputElement>) => {
            const newEvtTarget = {
                ...eventTarget,
            }
            newEvtTarget.target.value = eventTarget.target.value.replace(/\D/g, '').trim();
            onChange?.(newEvtTarget);
        }, [onChange])

        return (
            <Wrapper>
                {label && <Label for={name}>{label}</Label>}
                <Input
                    {...rest}
                    onChange={handleChange}
                    innerRef={ref}
                    name={name}
                />
            </Wrapper>
        );
    }
);

export default NumberInput;
