import { NotifyError } from "app/components/Toastify/Toastify";
import { useState } from "react";

const timeoutRequest = 1000 * 60 * 5;

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const accessToken = localStorage.getItem('accessToken');

  const downloadFile = async (targetUrl: string, fileName?: string) => {
    setIsLoading(true);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';

    if (fileName) {
      tempLink.setAttribute('download', fileName);
    }

    const controller = new AbortController();
    const id = setTimeout(() => {
      controller.abort();
      NotifyError('Превышен лимит ожидания');
    }, timeoutRequest);

    await fetch(targetUrl, {
      method: 'GET',
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (!response.ok) {
          NotifyError('Не удалось скачать файл!');
          throw Error(
            `error download file=${targetUrl}, error text=${response.statusText}`
          );
        }

        const disposition = response.headers.get('content-disposition');
        if (disposition && disposition.includes('attachment')) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches?.[1]) {
            tempLink.setAttribute('download', matches[1].replace(/['"]/g, ''));
          }
        }
        return response.blob();
      })
      .then((response) => {
        const blobData = [response];
        const blob = new Blob(blobData, {
          type: 'application/octet-stream',
        });

        const blobURL = window.URL?.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);
        tempLink.href = blobURL;

        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        return setTimeout(() => {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 200);
      });

    clearTimeout(id);
  };

  return {
    downloadFile,
    isLoading,
  };
};
