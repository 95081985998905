import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { WagonForm } from "../WagonForm/WagonForm";

type ReportModalProps = {
  onClose: () => void;
};

export function ReportModal({ onClose }: ReportModalProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Modal isOpen={true} toggle={handleClose}>
      <ModalHeader toggle={handleClose}>
        {t("main.headers.reports.biometry")}
      </ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink active>
              {t("report.train.form.wagonTab")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab="wagon">
          <TabPane tabId="wagon">
            <WagonForm onClose={handleClose} />
          </TabPane>
        </TabContent>
      </ModalBody>
    </Modal>
  );
}
