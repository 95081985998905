import "./theme/global.css";

import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { appRouter } from "./appRouter";

import {
  LoadingIndicator,
  LoadingWrapper,
} from "app/components/LoadingIndicator";
import { Provider } from "react-redux";
import { store } from "store";
import { I18nextProvider } from "react-i18next";
import { Suspense } from "react";

import i18n from "../locales/i18n";

function App() {
  return (
    <>
      <Suspense
        fallback={
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        }
      >
        <I18nextProvider i18n={i18n}>
          <ToastContainer />
          <Provider store={store}>
            <RouterProvider
              router={appRouter()}
              fallbackElement={
                <LoadingWrapper>
                  <LoadingIndicator />
                </LoadingWrapper>
              }
            />
          </Provider>
        </I18nextProvider>
      </Suspense>
    </>
  );
}

export default App;
