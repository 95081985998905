import { createApi } from "@reduxjs/toolkit/query/react";
import {
  DiscrepanciesDTO,
  ListResponse,
  RequestDataWrapper,
  RequestDiscrepancyTrain,
} from "store/types";
import axiosBaseQuery from "utils/request";
import { TrainDTO, TrainRequestDto } from "./types";

export const trainApi = createApi({
  reducerPath: "trainApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getTrains: builder.query<
      ListResponse<TrainDTO>,
      RequestDataWrapper<TrainRequestDto>
    >({
      query: ({ page, size, sort, sortColumn, filters }) => ({
        url: "/train-list",
        method: "GET",
        params: {
          number: page,
          size,
          sort: `${sortColumn},${sort}`,
          ...filters,
        },
      }),
    }),

    getTrainById: builder.query<TrainDTO, string | void>({
      query: (trainId) => ({
        url: `/train/${trainId}`,
        method: "GET",
      }),
    }),
    getDiscrepancies: builder.query<DiscrepanciesDTO, string | void>({
      query: (trainId) => ({
        url: `/train/discrepancies/${trainId}`,
        method: "GET",
      }),
    }),
    trainApprove: builder.mutation<
      void,
      {
        discrepancy: RequestDiscrepancyTrain;
        trainId: string;
      }
    >({
      query: ({ trainId, discrepancy }) => ({
        url: `/train/discrepancies/${trainId}`,
        method: "POST",
        data: discrepancy,
      }),
    }),
  }),
});

export const {
  useGetTrainsQuery,
  useLazyGetTrainsQuery,
  useGetTrainByIdQuery,
  useGetDiscrepanciesQuery,
  useTrainApproveMutation,
} = trainApi;
