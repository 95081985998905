import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { ListRequest } from "store/types";
import { biometryTrainApi } from "./api";
import { BioTrainWrapper, ResponseState } from "./types";

const defaultTrainsFilter: ListRequest = {
  filterId: nanoid(),
  filter: null,
  number: 0,
  size: 15,
  // sortField: "created_date",
  // sort: "desc",
  from: null,
  to: null,
};

const initialState: ResponseState = {
  trains: {
    filter: defaultTrainsFilter,
    result: {
      data: [],
      pageNumber: 0,
      pageSize: 0,
      totalElements: 0,
      totalPages: 0,
    },
  },
};

export const biometryTrainSlice = createSlice({
  name: "biometryTrain",
  initialState,
  reducers: {
    changeFilter: (state, action: PayloadAction<ListRequest>) => {
      state.trains.filter = action.payload;
    },
    resetFilter: (state) => {
      state.trains.filter = defaultTrainsFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      biometryTrainApi.endpoints.getTrains.matchFulfilled,
      (state, action: PayloadAction<BioTrainWrapper>) => {
        state.trains.result = action.payload;
      }
    );
  },
});

export const { changeFilter, resetFilter } = biometryTrainSlice.actions;

export default biometryTrainSlice.reducer;
