import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { DropDownContainer } from "./styles";
import styled from "styled-components";
import Link from "./NavLink";


const DropDownLink = styled(DropdownItem)`
  a {
    color: #000;
  }
`;

export function ReferencesMenu() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <DropDownContainer>
        <ButtonDropdown isOpen={menuOpen} toggle={handleToggle}>
          <DropdownToggle tag={"a"} caret>
            Справочники
          </DropdownToggle>
          <DropdownMenu>
            <DropDownLink>
              <Link to="/oversizeform">Индекс негабаритости</Link>
            </DropDownLink>
          </DropdownMenu>
        </ButtonDropdown>
      </DropDownContainer>
    </React.Fragment>
  );
}
