import styled from "styled-components";
import NavMenu from "../NavMenu";
import NavUser from "../NavUser";
import Logo from "./Logo";
import { Wrapper } from "./styles";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import { ModalLive } from "../CameraTrain";
import { useCallback } from "react";
import { useAppSelector } from "store";
import { selectUserIsAdmin } from "store/auth/reducer";
import { ReportsMenu } from "../NavUser/ReportsMenu";
import { ReferencesMenu } from "../NavUser/ReferencesMenu";

const InstructionContainer = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const InstructionLink = styled('div')({
  color: '#fff',
  textDecoration: 'underline',
  fontSize: 14,
  marginLeft: 15,
  cursor: 'pointer'
});

const PageHeader = () => {
  const { t } = useTranslation();
  const instructionModal = useModal(false);
  
  const isSuperAdmin = useAppSelector(selectUserIsAdmin);

  const handleDownloadWord = useCallback(async () => {
    window.open(
      `/api/instruction/word`,
      "_blank"
    );
  }, []);

  return (
    <>
      <Wrapper>
        <Logo />
        <NavMenu />
        {isSuperAdmin && <ReportsMenu />}
        {isSuperAdmin && <ReferencesMenu />}
        <InstructionContainer>
          <InstructionLink onClick={() => handleDownloadWord()}>{t('main.headers.instruction.doc')}</InstructionLink>
          <InstructionLink onClick={() => instructionModal.open()}>{t('main.headers.instruction.video')}</InstructionLink>
        </InstructionContainer>
        <NavUser />
      </Wrapper>
      {instructionModal.isOpen && (
        <ModalLive
          cameraLiveUrl="/api/instruction/stream"
          cameraDownloadUrl="/api/instruction/video"
          onClose={instructionModal.close}
        />
      )}
    </>
  );
};

export default PageHeader;