import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import RequireUser from "./components/RequireUser";
import { BaseLayout } from "./layouts/BaseLayout";
import { biometricRoutes } from "./pages/Biometry/routes";
import { usersRoutes } from "./pages/UsersPage/routes";

type AuthGuardProps = {
  children: React.ReactNode | null;
};

function AuthGuard({ children }: AuthGuardProps) {
  const isAuthorized = localStorage.getItem("userInfo");;
  if (isAuthorized) return <Navigate to="/" />;
  return (
    <React.Fragment>{children}</React.Fragment>
  );
}

export const appRouter = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/trains" replace />,
    },
    {
      path: "/login",
      async lazy() {
        const { LoginPage } = await import("./pages/LoginPage/LoginPage");

        return {
          element: (
            <AuthGuard>
              <LoginPage />
            </AuthGuard>
          ),
        };
      },
    },
    {
      path: "/users",
      element: (
        <BaseLayout>
          <RequireUser allowedRoles={["ADMIN"]} />
        </BaseLayout>
      ),
      children: usersRoutes,
    },
    {
      path: "/biometry",
      element: (
        <BaseLayout>
          <RequireUser allowedRoles={["ADMIN", "BIOMETRIC"]} />
        </BaseLayout>
      ),
      children: biometricRoutes,
    },
    {
      path: "/trains",
      element: <RequireUser allowedRoles={["ADMIN", "OPERATOR", "USER"]} />,
      children: [
        {
          index: true,
          async lazy() {
            const { TrainPage } = await import("./pages/TrainPage");

            return {
              element: <TrainPage />,
            };
          },
        },
        {
          path: ":id",
          async lazy() {
            const { TrainDetailPage } = await import("./pages/TrainDetailPage");
            return {
              element: <TrainDetailPage />,
            };
          },
        },
        {
          path: "car/:id/:wagonId",
          async lazy() {
            const { TrainCarPage } = await import("./pages/TrainCarPage");

            return {
              element: <TrainCarPage />,
            };
          },
        },
      ],
    },
    {
      path: "/journal",
      element: (
        <BaseLayout>
          <RequireUser allowedRoles={["ADMIN", "OPERATOR", "USER"]} />
        </BaseLayout>
      ),
      children: [
        {
          index: true,
          async lazy() {
            const { JournalPage } = await import("./pages/JournalPage");

            return {
              element: <JournalPage />,
            };
          },
        },
      ],
    },
    {
      path: "/profile",
      element: (
        <BaseLayout>
          <RequireUser
            allowedRoles={["ADMIN", "OPERATOR", "USER", "BIOMETRIC"]}
          />
        </BaseLayout>
      ),
      children: [
        {
          index: true,
          async lazy() {
            const { ProfilePage } = await import("./pages/ProfilePage");

            return {
              element: <ProfilePage />,
            };
          },
        },
      ],
    },
    {
      path: "/oversizeform",
      element: (
        <BaseLayout>
          <RequireUser allowedRoles={["ADMIN", "BIOMETRIC"]} />
        </BaseLayout>
      ),
      children: [
        {
          index: true,
          async lazy() {
            const { OversizeFormPage } = await import("./pages/OversizeFormPage");

            return {
              element: <OversizeFormPage />,
            };
          },
        },
      ],

    },
    {
      path: "*",
      async lazy() {
        const { NotFoundPage } = await import("./pages/ErrorPage/NotFound");

        return {
          element: <NotFoundPage />,
        };
      },
    },
    {
      path: "/404",
      async lazy() {
        const { NotFoundPage } = await import("./pages/ErrorPage/NotFound");

        return {
          element: <NotFoundPage />,
        };
      },
    },
    {
      path: "/403",
      async lazy() {
        const { NotPermissionPage } = await import(
          "./pages/ErrorPage/NotPermission"
        );

        return {
          element: <NotPermissionPage />,
        };
      },
    },
  ]);
};
