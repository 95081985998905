import { z } from "zod";

export const changePasswordFormSchema = z
  .object({
    validPassword: z.boolean(),
    newPassword: z
      .string({
        required_error: "Это поле обязательно для заполнения",
      })
      .min(8, "Не менее 8-ми символов"),
    oldPassword: z
      .string({
        required_error: "Это поле обязательно для заполнения",
      })
      .min(8, "Не менее 8-ми символов"),
    confirmPassword: z.string({
      required_error: "Это поле обязательно для заполнения",
    }),
  })
  .refine(
    (values) => {
      return values.newPassword === values.confirmPassword;
    },
    {
      message: "Пароли не совпадают!",
      path: ["confirmPassword"],
    }
  );

export type ChangePasswordFormSchema = z.infer<typeof changePasswordFormSchema>;
