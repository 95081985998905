import { TrainReportModal } from "app/features/reports/train";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { DropDownContainer } from "./styles";

export function ReportsMenu() {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [trainReportDialog, setTrainReportDialog] = useState<boolean>(false);

  const handleToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleToggleTrainDialog = () => {
    setTrainReportDialog((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <DropDownContainer>
        <ButtonDropdown isOpen={menuOpen} toggle={handleToggle}>
          <DropdownToggle tag={"a"} caret>
            {t("main.headers.reports")}
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem tag={"a"} onClick={handleToggleTrainDialog}>
              {t("main.headers.reports.biometry")}
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </DropDownContainer>

      {trainReportDialog && <TrainReportModal onClose={handleToggleTrainDialog} />}
    </React.Fragment>
  );
}
