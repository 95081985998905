import { createApi } from "@reduxjs/toolkit/query/react";
import {
  DiscrepanciesDTO,
  RequestDiscrepancyWagon,
  WagonCorrectionDto,
} from "store/types";
import axiosBaseQuery from "utils/request";
import {
  WagonContainerDto,
  WagonContainerRequest,
  WagonCorrectionRequest,
  WagonDTO,
  WagonFilterDTO,
  WagonPhotoDTO,
  WagonWrapper,
} from "./types";

export const wagonApi = createApi({
  reducerPath: "wagonApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api",
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ["WAGON_CORRECTIONS", "WAGON_GET_ID"],
  endpoints: (builder) => ({
    getWagonById: builder.query<WagonDTO, string | void>({
      query: (wagonId) => ({
        url: `/wagon/${wagonId}`,
        method: "GET",
      }),
      providesTags: ["WAGON_GET_ID"],
    }),

    getWagons: builder.query<WagonWrapper, string | void>({
      query: (trainId) => ({
        url: `/wagons/${trainId}`,
        method: "GET",
      }),
    }),

    getWagonsWithFilter: builder.query<WagonWrapper, WagonFilterDTO>({
      query: ({ trainId, params }) => ({
        url: `/wagons/${trainId}`,
        method: "GET",
        params: params,
      }),
    }),

    getDiscrepancies: builder.query<
      DiscrepanciesDTO,
      {
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId }) => ({
        url: `/wagons/discrepancies?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "GET",
      }),
    }),

    wagonApprove: builder.mutation<
      void,
      {
        discrepancy: RequestDiscrepancyWagon;
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId, discrepancy }) => ({
        url: `/wagons/discrepancies/?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "POST",
        data: discrepancy,
      }),
    }),

    wagonCorrect: builder.mutation<void, WagonCorrectionRequest>({
      query: ({ trainId, wagonId, number, userName }) => ({
        url: `/wagon/correct/?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "PUT",
        data: {
          number,
          userName,
        },
      }),
      invalidatesTags: ['WAGON_CORRECTIONS', "WAGON_GET_ID"]
    }),

    wagonCorrections: builder.query<
      WagonCorrectionDto[],
      {
        wagonId: string;
      }
    >({
      query: ({ wagonId }) => ({
        url: `/wagon/get-changes-list/?wagon_id=${wagonId}`,
        method: "GET",
      }),
      providesTags: ["WAGON_CORRECTIONS"],
    }),

    getPhotos: builder.query<
      WagonPhotoDTO,
      {
        trainId: string;
        wagonId: string;
      }
    >({
      query: ({ trainId, wagonId }) => ({
        url: `/wagons/photos?train_id=${trainId}&wagon_id=${wagonId}`,
        method: "GET",
      }),
    }),

    getWagonsContainers: builder.query<
      WagonContainerDto[],
      WagonContainerRequest
    >({
      query: ({ wagonId }) => ({
        url: `/containers/${wagonId}`,
        method: "GET",
      }),
    }),

    getContainersPhotos: builder.query<
      WagonPhotoDTO & {
        containerId: string;
      },
      WagonContainerRequest
    >({
      query: ({ containerId }) => ({
        url: `/containers/${containerId}/photos`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetWagonsWithFilterQuery,
  useGetWagonsQuery,
  useGetDiscrepanciesQuery,
  useWagonApproveMutation,
  useGetPhotosQuery,
  useGetWagonByIdQuery,
  useWagonCorrectMutation,
  useWagonCorrectionsQuery,
  useGetWagonsContainersQuery,
  useGetContainersPhotosQuery,
} = wagonApi;
