import { Box } from "app/components";
import Button from "app/components/Button";
import { RangePicker } from "app/components/Inputs/DatePicker";
import {
  LoadingIndicator,
  LoadingWrapper,
} from "app/components/LoadingIndicator";
import { useDownload } from "hooks/useDownload";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormGroup } from "reactstrap";
import { dateFormat, formatDateString, stringToDate } from "utils/dateHelper";

type WagonReportRequest = {
  startDate: string;
  endDate: string;
};

type WagonFormProps = {
  onClose: () => void;
};

export function WagonForm({ onClose }: WagonFormProps) {
  const { t } = useTranslation();
  const { downloadFile, isLoading } = useDownload();
  const { handleSubmit, setValue, getValues } = useForm<WagonReportRequest>();

  const onSubmitForm = handleSubmit(async ({ startDate, endDate }) => {
    await downloadFile(`/api/report?startDate=${startDate}&endDate=${endDate}`);
    onClose();
  });

  const handleChangeDate = (
    date: [Date | null, Date | null],
    _dateString: string[]
  ) => {
    if (date.length > 0) {
      setValue("startDate", formatDateString(date[0], dateFormat));
      setValue("endDate", formatDateString(date[1], dateFormat));
    }
  };

  return (
    <React.Fragment>
      {isLoading && (
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      )}
      <Box sx={{ padding: "16px" }}>
        <FormGroup>
          <Box sx={{ display: "flex" }}>
            <RangePicker
              onChange={handleChangeDate}
              labelPosition="vertical"
              labels={[
                `${t("main.component.datepicker.from")}:`,
                `${t("main.component.datepicker.to")}:`,
              ]}
              values={[
                stringToDate(getValues()?.startDate ?? "", dateFormat),
                stringToDate(getValues()?.endDate ?? "", dateFormat),
              ]}
            />
          </Box>
          <FormFeedback className="d-block">
            {t("report.train.form.period.label")}
          </FormFeedback>
        </FormGroup>
        <Box
          sx={{
            marginTop: "26px",
            display: "flex",
            gap: "16px",
          }}
        >
          <Button color="primary" onClick={onSubmitForm}>
            {t("report.train.form.download")}
          </Button>{" "}
          <Button color="secondary" onClick={onClose}>
            {t("main.component.form.btn.close")}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
}
