import { createApi } from "@reduxjs/toolkit/query/react";
import { RequestDataWrapper, ResponseDataWrapper } from "store/types";
import axiosBaseQuery from "utils/request";
import { UserLogsDto, UsersLogsRequest, UserResponse, UserSign, UserRequestDto } from "./types";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: axiosBaseQuery({
        baseUrl: "/api",
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getUsers: builder.query<ResponseDataWrapper<UserResponse>, RequestDataWrapper<UserRequestDto>>({
            query: ({ page, size, sortColumn, sort, filters }) => ({
              url: "/users",
              method: "GET",
              params: {
                page,
                size,
                ...(sort && sortColumn
                  ? {
                      sort: `${sortColumn},${sort}`,
                    }
                  : {}),
                ...filters,
              },
            }),
          }),
        getUser: builder.query<UserResponse, string | void>({
            query: (userId) => ({
                url: `/user/${userId}`,
                method: "GET"
            }),
        }),
        createUser: builder.mutation<{
            message: string
        }, UserSign>({
            query: (args) => ({
                url: "/auth/sign-up",
                method: "POST",
                data: args,
            }),
        }),
        updateUser: builder.mutation<{
            message: string
        }, UserSign & {
            id: string
        }>({
            query: (args) => ({
                url: `/user/${args.id}`,
                method: "PUT",
                data: args,
            }),
        }),

        getUsersLogs: builder.query<ResponseDataWrapper<UserLogsDto>, RequestDataWrapper<UsersLogsRequest>>({
            query: ({
                page, size, sort, sortColumn, filters
            }) => ({
                url: "/event-log",
                method: "GET",
                params: {
                    page,
                    size,
                    sort: `${sortColumn},${sort}`,
                    ...filters,
                },
            }),
        }),
    }),
});

export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetUserQuery,
    useGetUsersLogsQuery,
    useLazyGetUsersLogsQuery,
    useLazyGetUsersQuery,
} = userApi;
